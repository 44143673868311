<script>
import StatsService from "@/api/services/stats.service";
import Multiselect from "vue-multiselect";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error"
import { dataPerCalendarWeekChart } from "@/redem/stats/result-charts.js";

export default {
  components: {
    Multiselect,
    DataFetchingErrorWidget
  },
  data() {
    return {
      dataPerCalendarWeekChartData: {
        name: "Number of Data Records",
        data: []
      },
      dataPerCalendarWeekChartOptions: null,
      /****** Filter Options *****/
      selectedYear: null,
      years: [2021, 2022, 2023, 2024, 2025],
      /****** Operational Data *****/
      isComponentLoading: false,
      errorInFetchingData: false
    }
  },
  async mounted() {
    // 1. Create chart option
    this.dataPerCalendarWeekChartOptions = new dataPerCalendarWeekChart()

    // 2. Set selectedYear
    this.selectedYear = new Date().getFullYear()

    // 3. Fetching data
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        // 1. Enable loading
        this.isComponentLoading = true

        // 2. Fetch Data
        const response = await StatsService.getDataRecordsPerCalendarWeek(this.selectedYear)

        // 3. Make Chart Data variables to default values 
        this.dataPerCalendarWeekChartOptions.chartOptions.xaxis.categories = []
        this.dataPerCalendarWeekChartData.data = []

        // 4. Set Data for the Chart
        if(response && response.length > 0) {
          for(let i=0; i < response.length; i++) {
            this.dataPerCalendarWeekChartOptions.chartOptions.xaxis.categories.push(response[i].week)
            this.dataPerCalendarWeekChartOptions.chartOptions.yaxis.title.text = "Number of Data Records"
            this.dataPerCalendarWeekChartData.data.push(response[i].numberOfDataRecords)
          }
        }
        
        // 5. Enable loading
        this.isComponentLoading = false
      } catch(error) {
        this.isComponentLoading = false
        this.errorInFetchingData = true
      }
    },
    async changeSelectedYear() {
      await this.fetchData()
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="row">
          <div class="col-md-10">
            Number of Respondent Per Calendar Week
          </div>
          <div class="col-md-2">
            <multiselect
              v-model="selectedYear"
              deselect-label="Can't remove this value"
              :options="years"
              :searchable="false"
              :close-on-select="true"
              :allowEmpty="false"
              placeholder="DE Pick a value"
              @input="changeSelectedYear()"
            >
            </multiselect>
          </div>
        </div>
      </div>

      <!-- Display Chart -->
      <div class="card-text" v-if="!isComponentLoading">
        <apexchart
          ref="distributionChart"
          class="apex-charts"
          dir="ltr"
          type="bar"
          height="300"
          :series="[dataPerCalendarWeekChartData]"
          :options="dataPerCalendarWeekChartOptions.chartOptions"
        ></apexchart>
      </div>

      <!-- Display Data Fetching Error -->
      <div class="card-text" v-if="!isComponentLoading && errorInFetchingData">
        <DataFetchingErrorWidget/>
      </div>

      <!-- Display Loading -->
      <div class="card-text" v-if="isComponentLoading">
        <div class="row justify-content-center mt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
      
    </div>
  </div>
</template>