<script>
import DatafileService from "@/api/services/datafile.service.js";
import LiveCleaningStatus from "@/shared/enums/liveCleaningStatus";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String,
    cleaned: Boolean,
    onlyOneQulityScore: Boolean,
    availableQualityScore: String
  },
  data() {
    return {
      metadata: null,
      datafile: null,
      liveCleaningStatus: LiveCleaningStatus,
      enableBenchmarks: false,
      /***** Operational Data *****/
      isFetchingMetadataError: false,
      isFetchingDatafileError: false,
      isEnableToEditDatafileTitle: false
    };
  },
  async created() {
    await this.fetchAllData();
    this.$emit("dataFetchingDone");
    EventBus.$on("updateDatafileMetadata", async () => {
      await this.fetchMetadata();
    });
  },
  methods: {
    async fetchAllData() {
      const metadata = this.fetchMetadata();
      const datafile = this.fetchDatafile();

      try {
        await metadata;
      } catch (e) {
        this.isFetchingMetadataError = true;
        console.log("Error fetching metadat data");
      }
      try {
        await datafile;
      } catch (e) {
        this.isFetchingDatafileError = true;
        console.log("Error fetching datafile data");
      }
    },
    async fetchMetadata() {
      const metadataFromFunction = await DatafileService.getDatafileMetadata(
        this.datafileId,
        this.cleaned
      );
      this.metadata = metadataFromFunction;
    },
    async fetchDatafile() {
      this.datafile = await DatafileService.getDatafile(this.datafileId);
    },
    clickEditDatafileTitle() {
      this.isEnableToEditDatafileTitle = true
    },
    async changeDatafileTitle(item, event) {
      const oldDatafileTitle = item
      const newDatafileTitle = event.target.value

      try {
        this.metadata.title = newDatafileTitle
        await DatafileService.editDatafileTitle(this.datafileId, newDatafileTitle)
      } catch(error) {
        this.metadata.title = oldDatafileTitle
        this.editDatafileTitleFailed = true
      }
      this.isEnableToEditDatafileTitle = false
    },
    toggleBenchmarking() {
      this.$emit('toggleBenchMark', this.enableBenchmarks);
    }
  }
};
</script>

<template>
  <div
    class="card"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' },
      this.cleaned ? { height: '150px' } : { height: '250px' }
    ]"
  >
    <div class="card-body">
      <!-- Datafile title for Overview -->
      <b-row>
        <b-col sm="6">
          <span
            @click="clickEditDatafileTitle()"
            v-if="!isEnableToEditDatafileTitle"
            class="hover-on-datafile-title"
          >
            {{ metadata.title }}
          </span>
          <span v-if="isEnableToEditDatafileTitle">
            <input
              :value="metadata.title"
              class="form-control"
              name="datafileTitle"
              placeholder="Edit datafile title"
              @change="changeDatafileTitle(metadata.title, $event)"
            />
          </span>
        </b-col>

        <b-col sm="6" class="text-right">
          <b-badge
            style="font-size: 12px"
            variant="primary"
            v-if="this.datafile.isWeighted"
            class="mr-2"
          >
            <i class="bx bx-slider mr-1"></i>
            {{ $t("datafileResultCleanedTab.metadataWidget.weightedTag") }}
          </b-badge>
          <b-badge
            style="font-size: 12px"
            variant="light"
            v-if="datafile.isLiveCleaning === liveCleaningStatus.running"
          >
            <i class="bx bxs-circle bx-flashing text-success"></i>
            <span class="text-success"> LIVE </span>
          </b-badge>
          <b-badge
            style="font-size: 12px"
            variant="light"
            v-if="datafile.isLiveCleaning === liveCleaningStatus.stop"
          >
            <i class="bx bxs-flag-checkered"></i>
            <span class=""> FINISHED </span>
          </b-badge>
        </b-col>
      </b-row>
        

      <!-- Datafile Created By -->
      <div class="card-text mt-2">
        <small class="text-muted">
          {{ $t("datafileResultRawTab.metadataWidget.uploadedBy") }}
          {{ metadata.createdBy }}
        </small>
      </div>
      
      <!-- General metadata -->
      <div class="card-text mt-4">
        <b-row>
          <!-- All Data Records -->
          <b-col sm="2" class="mb-3" v-if="!this.cleaned">
            <div>
              {{
                $t("datafileResultRawTab.metadataWidget.numberOfDataRecords")
              }}
            </div>
            <div>
              <strong>{{ metadata.numberOfRecords }}</strong>
            </div>
          </b-col>

          <!-- Data Records without Scores -->
          <b-col sm="2" class="mb-3" v-if="!this.cleaned">
            <div>
              {{
                $t(
                  "datafileResultRawTab.metadataWidget.numberOfInvalidDataRecords"
                )
              }}
            </div>
            <div>
              <strong>{{ metadata.numberOfInvalidRecords }}</strong>
            </div>
          </b-col>

          <!-- Number of Data Points -->
          <b-col sm="2" class="mb-3">
            <div>
              {{ $t("datafileResultRawTab.metadataWidget.numberOfDataPoints") }}
            </div>
            <div>
              <strong>{{ metadata.numberOfDatapoints }}</strong>
            </div>
          </b-col>

          <!-- Number of Quality Scores -->
          <b-col sm="2" class="mb-3" v-if="!onlyOneQulityScore">
            <div>
              {{
                $t("datafileResultRawTab.metadataWidget.numberOfQualityScores")
              }}
            </div>
            <div>
              <strong>{{ metadata.numberOfQualityScores }}</strong>
            </div>
          </b-col>

          <!-- Data of Creation  -->
          <b-col sm="2" class="mb-3">
            <div>
              {{ $t("datafileResultRawTab.metadataWidget.dateOfCreation") }}
            </div>
            <div>
              <strong>{{
                String(metadata.dateOfCreation).slice(0, 10)
              }}</strong>
            </div>
          </b-col>

          <!-- Number of Credits  -->
          <!-- <b-col sm="2" class="mb-3">
            <div>
              {{ $t("datafileResultRawTab.metadataWidget.creditCount") }}
            </div>
            <div>
              <strong>{{
                metadata.cost.toLocaleString("de-DE", {
                  minimumFractionDigits: 2
                })
              }}</strong>
            </div>
          </b-col> -->

          <!-- Wrong Topic Check Enabled -->
          <b-col sm="2" class="mb-3" v-if="onlyOneQulityScore && availableQualityScore==='OES'">
            <div>
              {{
                $t("datafileResultRawTab.metadataWidget.contextCheck")
              }}
            </div>
            <span v-if="metadata.isEnableWrongTopicCheck">
              <b-badge style="font-size: 12px" variant="info">
                {{
                  $t("datafileResultRawTab.metadataWidget.yes")
                }}
              </b-badge>
            </span>
            <span v-if="!metadata.isEnableWrongTopicCheck">
              <b-badge style="font-size: 12px" variant="info">
                {{
                  $t("datafileResultRawTab.metadataWidget.no")
                }}
              </b-badge>
            </span>
          </b-col> 

          <!-- Add Missing Values to Here  -->
          <b-col
            sm="4"
            class="mb-3"
            v-if="metadata.missingValues && metadata.missingValues.length > 0"
          >
            <div>
              {{ $t("datafileResultCleanedTab.metadataWidget.missingValues") }}
            </div>
            <div class="mt-1">
              <b-badge
                v-for="(
                  missingValue, missingValueIndex
                ) in metadata.missingValues"
                :key="missingValueIndex"
                class="mr-1"
                style="font-size: 12px"
                variant="dark"
              >
                {{ missingValue }}
              </b-badge>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3" v-if="!this.cleaned">
          <b-col>
            <b-form-checkbox
              v-model="enableBenchmarks"
              name="check-button"
              @change="toggleBenchmarking()"
              switch
            >
              <strong> {{ $t("datafileResultRawTab.metadataWidget.enableBenchmark") }} </strong>
              <b-badge class="mr-1" style="font-size: 12px" variant="success">
                {{$t("general.new")}}
              </b-badge>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hover-on-datafile-title {
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 4px;
}
.hover-on-datafile-title:hover {
  border: 1px solid #e3e3e3;
  padding: 5px;
  border-radius: 4px;
}
</style>
