<script>
import AuthService from "@/api/services/auth.service.js";
import AccessLevel from "@/shared/enums/aceessLevels.js";

/***  Profile Card ***/
export default {
  data() {
    return {
      loggedInUser: {
        name: {}
      },
      accessLevelEnum: AccessLevel
    };
  },
  async mounted() {
    await this.getLoggedInUser();
  },
  computed: {
    localeFormat() {
      return "de-DE";
    }
  },
  methods: {
    async getLoggedInUser() {
      let getLogedInuserFromQuery = await AuthService.getLoggedInUser();
      if (getLogedInuserFromQuery) {
        this.loggedInUser = getLogedInuserFromQuery;
      }
    }
  }
};
</script>

<template>
  <div class="card overflow-hidden">
    <div class="bg-white" style="height: 100px">
      <div class="row">
        <div class="col-7">
          <div class="text-primary p-3">
            <h5 class="text-primary">
              {{ $t("dashboard.welcomeCard.welcome") }}
            </h5>
            <h5 class="font-size-15">
              {{ this.loggedInUser.name.first }}
              {{ this.loggedInUser.name.last }}
            </h5>
            <b-badge variant="warning">
              <span
                v-if="
                  this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN
                "
              >
                {{ $t("dashboard.welcomeCard.accessLevel.superAdmin") }}
              </span>
              <span
                v-if="this.loggedInUser.accessLevel === accessLevelEnum.ADMIN"
              >
                {{ $t("dashboard.welcomeCard.accessLevel.admin") }}
              </span>
              <span
                v-if="
                  this.loggedInUser.accessLevel === accessLevelEnum.EMPLOYEE
                "
              >
                {{ $t("dashboard.welcomeCard.accessLevel.employee") }}
              </span>
            </b-badge>
          </div>
        </div>
        <div class="col-5 text-right">
          <img class="mt-1 mr-2" src="@/assets/images/Winter.png" alt style="width: 120px" />
        </div>
      </div>
    </div>
  </div>
</template>
