import CustomErrorMessage from "../../shared/util/customErrorMessage.js";
import { ApiCall } from "../apiBase";

class DataImportService {
	/************ Validate Upload Process Step 2 User input *************/
  async validateUploadStep2Selections(validateInput) {
		let response = await ApiCall({
				method: "post",
				data: {
					query: `
						query validateUploadStep2Selections($validateInput: ValidateInput!) {
							validateUploadStep2Selections(validateInput: $validateInput) {
								responseStatus
								errors {
                  dataPoint
                  errorCode
                  errorMessage
                }
							}
						}
					`,
					variables: {
						validateInput: validateInput
					}
				}
		});
		const returnObj = response?.data?.data?.validateUploadStep2Selections ?? {};
		if (response?.data?.errors && response.data.errors.length > 0) returnObj.serverError = true;
    return returnObj;
	}
	async getDatafileHeaderRow({ datafileKey, headerRow, dataRecordStartRow, datafileId, sheetName }) {
		let response = await ApiCall({
			method: "post",
			data: {
				query: `
				query getDatafileHeaderRow($datafileKey: String!, $headerRow: Int, $dataRecordStartRow: Int, $datafileId: ID, $sheetName: String) {
					getDatafileHeaderRow(datafileKey: $datafileKey, headerRow: $headerRow, dataRecordStartRow: $dataRecordStartRow, datafileId: $datafileId, sheetName: $sheetName) {
						headerRowPayload
						numberOfRespondents
					}
				}
				`,
				variables: {
					datafileKey,
					headerRow,
					dataRecordStartRow,
					datafileId,
					sheetName
				}
			}
		});
		if (response?.data?.data?.getDatafileHeaderRow && !response?.data?.errors) {
      return response.data.data.getDatafileHeaderRow;
    } else {
			throw new CustomErrorMessage(
				response?.data?.errors[0]?.extensions?.code,
				response?.data?.errors[0].message
			);
    }
	}
	async checkAllowedForDataImport(creditAmount, companyId) {
		const response = await ApiCall({
			method: "post",
			data: {
				query: `
				query checkAllowedForDataImport($creditAmount: Float!, $companyId: ID!) {
					checkAllowedForDataImport(creditAmount: $creditAmount, companyId: $companyId)
				}
				`,
				variables: {
					creditAmount,
					companyId
				}
			}
		});

		if (response?.data?.errors) {
			throw new CustomErrorMessage(
				response.data.errors[0]?.extensions?.code,
				response.data.errors[0]?.message
			);
		}

		return response?.data?.data?.checkAllowedForDataImport ?? false;
	}

}

 export default new DataImportService();