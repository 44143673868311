var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", staticStyle: { "min-height": "610px" } },
    [
      _vm.isComponentLoading
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-text" }, [
              _c("div", { staticClass: "row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 text-center p-5" },
                  [
                    _c("b-spinner", {
                      staticClass: "m-2",
                      staticStyle: { width: "5rem", height: "5rem" },
                      attrs: { variant: "mid", role: "status" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isComponentLoading &&
      this.transactions &&
      this.transactions.length > 0
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-text" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "medium",
                        "font-weight": "600",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("adminComponents.transactionWidget.title")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-4 text-right" }),
              ]),
              _c("div", { staticClass: "row mt-4" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "search",
                        placeholder: _vm.$t(
                          "adminComponents.transactionWidget.searchPlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-9 text-right" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "10px",
                          "text-align": "right",
                        },
                        on: { change: _vm.selectAllReceivedTransactions },
                        model: {
                          value: _vm.showAllReceivedTransactions,
                          callback: function ($$v) {
                            _vm.showAllReceivedTransactions = $$v
                          },
                          expression: "showAllReceivedTransactions",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.transactionWidget.transactionTypes.received"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "b-form-checkbox",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "10px",
                          "text-align": "right",
                        },
                        on: { change: _vm.selectAllUsedTransactions },
                        model: {
                          value: _vm.showAllUsedTransactions,
                          callback: function ($$v) {
                            _vm.showAllUsedTransactions = $$v
                          },
                          expression: "showAllUsedTransactions",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.transactionWidget.transactionTypes.used"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "b-form-checkbox",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "10px",
                          "text-align": "right",
                        },
                        on: { change: _vm.selectAllUsedTransactions },
                        model: {
                          value: _vm.showAllExpiredTransactions,
                          callback: function ($$v) {
                            _vm.showAllExpiredTransactions = $$v
                          },
                          expression: "showAllExpiredTransactions",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.transactionWidget.transactionTypes.expired"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "b-form-checkbox",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "10px",
                          "text-align": "right",
                        },
                        on: { change: _vm.selectAllRefundedTransactions },
                        model: {
                          value: _vm.showAllRefundedTransactions,
                          callback: function ($$v) {
                            _vm.showAllRefundedTransactions = $$v
                          },
                          expression: "showAllRefundedTransactions",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.transactionWidget.transactionTypes.refunded"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt-4" }, [
                _c(
                  "div",
                  { staticClass: "table-responsive mb-0" },
                  [
                    _c("b-table", {
                      attrs: {
                        items: _vm.dataForTable,
                        fields: _vm.fieldsForTable,
                        responsive: "sm",
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage,
                        bordered: true,
                        filter: _vm.filter,
                        "filter-included-fields": ["description"],
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(create)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.formatDate(data.value)) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(amount)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatCredit(data.value)) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(creditPlans)",
                            fn: function (data) {
                              return _vm._l(data.value, function (creditPlan) {
                                return _c("div", { key: creditPlan.id }, [
                                  _vm._v(" " + _vm._s(creditPlan) + " "),
                                ])
                              })
                            },
                          },
                          {
                            key: "cell(numberOfUpdates)",
                            fn: function (scope) {
                              return [
                                scope.item.type !== "USED"
                                  ? _c("span", [_vm._v(" - ")])
                                  : _vm._e(),
                                scope.item.type === "USED"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              scope.item.numberOfUpdates
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell(latestTransactionDate)",
                            fn: function (scope) {
                              return [
                                scope.item.type !== "USED"
                                  ? _c("span", [_vm._v(" - ")])
                                  : _vm._e(),
                                scope.item.type === "USED"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(
                                              scope.item.latestTransactionDate
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell(type)",
                            fn: function (data) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-soft-secondary font-size-12",
                                    class: {
                                      "badge-soft-success":
                                        `${data.value}` === "RECEIVED" ||
                                        `${data.value}` === "REFUND",
                                      "badge-soft-danger":
                                        `${data.value}` === "USED",
                                      "badge-soft-gray":
                                        `${data.value}` === "EXPIRED",
                                    },
                                  },
                                  [
                                    data.value === "USED"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "adminComponents.transactionWidget.transactionTypes.used"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    data.value === "RECEIVED"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "adminComponents.transactionWidget.transactionTypes.received"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    data.value === "EXPIRED"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "adminComponents.transactionWidget.transactionTypes.expired"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    data.value === "REFUND"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "adminComponents.transactionWidget.transactionTypes.refunded"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(moreInfo)",
                            fn: function (scope) {
                              return [
                                _c("i", {
                                  staticClass: "bx bx-info-circle",
                                  staticStyle: {
                                    "font-size": "15px",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickOrganizationinfo(
                                        scope.item
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3555657828
                      ),
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dataTables_paginate paging_simple_numbers float-right",
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "pagination pagination-rounded mb-0" },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.rows,
                              "per-page": _vm.perPage,
                            },
                            model: {
                              value: _vm.currentPage,
                              callback: function ($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isComponentLoading &&
      this.transactions.length === 0 &&
      !this.isFetchingDataError
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-text" }, [
              _c("div", { staticClass: "row pt-5" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("img", {
                    staticClass: "img-fluid mb-4 mt-3",
                    staticStyle: { width: "60px" },
                    attrs: { src: require("@/assets/images/information.png") },
                  }),
                  _c("h6", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "adminComponents.transactionWidget.noTransactionsToDisplay.title"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "adminComponents.transactionWidget.noTransactionsToDisplay.text1"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isComponentLoading && this.isFetchingDataError
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-text" }, [
              _c("div", { staticClass: "row pt-5" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("img", {
                    staticClass: "img-fluid mb-4 mt-3",
                    staticStyle: { width: "60px" },
                    attrs: { src: require("@/assets/images/red-warning.png") },
                  }),
                  _c("h6", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "adminComponents.transactionWidget.dataFetchingError"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(" " + _vm._s(this.dataFetchingnErrorMessage) + " "),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }