import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true
    },
    component: () => import("../redem/dashboards/pages/default")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../redem/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../redem/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/create-account",
    name: "Create Account",
    component: () => import("../redem/account/create-account"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("../redem/account/welcome"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/new-password",
    name: "New Password",
    component: () => import("../redem/account/new-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../redem/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");
        localStorage.removeItem("selectedColumns");
        localStorage.removeItem("workingMetaDataArray");
        localStorage.removeItem("datafileInputObject");
        localStorage.removeItem("datafileMetaDataArray");

        // clean local storage item for define datafile
        window.localStorage.removeItem("pages");
        window.localStorage.removeItem("liveDatafileMetadata");
        window.localStorage.removeItem("enableTotalSurveyDuration");
        window.localStorage.removeItem("maximumCreditAmountForRespondent");
        window.localStorage.removeItem("enebaleCleaningThreshold");
        window.localStorage.removeItem("cleaningSettings");
        window.localStorage.removeItem("numberOfPagesCreated");
        window.localStorage.removeItem("initialSelectionofLanguagesToCheck");
        window.localStorage.removeItem("isEnableWrongTopicCheck");

        // clean session storgae item for the admin panel oragnizations table
        window.sessionStorage.removeItem("PaginationAdminPanelOrganizationTabel");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      }
    }
  },
  {
    path: "/404",
    name: "404",
    component: require("../redem/utility/404").default
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404"
  },
  {
    path: "/admin-panel",
    name: "admin-panel",
    component: require("../redem/admin-panel/pages/index.vue").default
  },
  {
    path: "/upload-dataset/step1",
    name: "upload-dataset-step1",
    meta: { authRequired: true },
    component: () => import("../redem/upload-dataset/step1")
  },
  {
    path: "/upload-dataset/step2",
    name: "upload-dataset-step2",
    meta: { authRequired: true },
    component: () => import("../redem/upload-dataset/step2")
  },
  {
    path: "/upload-dataset/step3",
    name: "upload-dataset-step3",
    meta: { authRequired: true },
    component: () => import("../redem/upload-dataset/step3")
  },
  {
    path: "/datafiles",
    name: "datafiles",
    meta: { authRequired: true },
    component: () => import("../redem/datafiles/pages/index")
  },
  {
    path: "/datafile-result",
    name: "datafile-result",
    meta: { authRequired: true },
    component: () => import("../redem/datafiles/pages/datafile-result")
  },
  {
    path: "/datafile-work-sheet",
    name: "datafile-work-sheet",
    meta: { authRequired: true },
    component: () => import("../redem/datafiles/pages/work-sheet.vue")
  },
  {
    path: "/guides",
    name: "Guides",
    meta: { authRequired: true },
    component: () => import("../redem/guides/index")
  },
  {
    path: "/faq-guide",
    name: "FAQ Guides",
    meta: { authRequired: true },
    component: () => import("../redem/guides/faq")
  },
  {
    path: "/upload-guide",
    name: "Upload Guides",
    meta: { authRequired: true },
    component: () => import("../redem/guides/upload-guide")
  },
  {
    path: "/projective-question-guide",
    name: "Projective Question Guides",
    meta: { authRequired: true },
    component: () => import("../redem/guides/projective-question-guide")
  },
  {
    path: "/analysis-guide",
    name: "Analysis Guides",
    meta: { authRequired: true },
    component: () => import("../redem/guides/analysis-guide")
  },
  {
    path: "/datafile-report",
    name: "datafile-report",
    meta: { authRequired: false },
    component: () => import("../redem/report/pages/index")
  },
  {
    path: "/report-verification",
    name: "report-verification",
    meta: { authRequired: false },
    component: () => import("../redem/report/pages/report-verification")
  },
  {
    path: "/credit-roi-calculator",
    name: "cost-calculator",
    meta: { authRequired: false },
    component: () => import("../redem/ROI-calculator/pages/index")
  },
  {
    path: "/live-import",
    name: "live-import",
    meta: { authRequired: true },
    component: () => import("../redem/live-import/pages/index")
  },
  {
    path: "/snippet-integration-instructions",
    name: "snippet-integration-instructions",
    meta: { authRequired: true },
    component: () =>
      import("../redem/datafiles/pages/snippet-integration-instructions")
  },
  {
    path: "/define-datafile",
    name: "define-datafile",
    meta: { authRequired: true },
    component: () => import("../redem/live-import/pages/define-datafile")
  },
  {
    path: "/data-files-stats",
    name: "data-files-stats",
    meta: { authRequired: true },
    component: () => import("../redem/stats/pages/data-files-stats")
  },
  {
    path: "/data-records-stats",
    name: "data-records-stats",
    meta: { authRequired: true },
    component: () => import("../redem/stats/pages/data-records-stats")
  },
  {
    path: "/monitoring",
    name: "monitoring",
    meta: { authRequired: true },
    component: () => import("../redem/monitoring/pages/index")
  },
  {
    path: "/organization-details",
    name: "organization-details",
    component: require("../redem/admin-panel/pages/organization-details").default
  },
  {
    path: "/administration",
    name: "organization-administration",
    component: require("../redem/administration/pages/index").default
  },
];
