var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "sidebar-menu" } },
    [
      _c("Snowf", {
        attrs: {
          amount: 50,
          size: 4,
          speed: 1.5,
          wind: 0,
          opacity: 0.8,
          swing: 1,
          image: null,
          zIndex: null,
          resize: true,
          color: "#fff",
        },
      }),
      _c(
        "ul",
        { staticClass: "metismenu list-unstyled", attrs: { id: "side-menu" } },
        [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "side-nav-link-ref",
                  attrs: { tag: "a", to: "/" },
                },
                [
                  _c("i", { staticClass: "bx bx-home-circle" }),
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("menus.sideMenu.dashboard")) + " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("li", { staticClass: "d-none d-lg-block" }, [
            _c(
              "a",
              {
                staticClass: "is-parent",
                attrs: { href: "javascript:void(0);" },
              },
              [
                _c("i", { staticClass: "bx bx-import" }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("menus.sideMenu.importSection.mainTitle"))
                  ),
                ]),
              ]
            ),
            _c(
              "ul",
              { staticClass: "sub-menu", attrs: { "aria-expanded": "false" } },
              [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "side-nav-link-ref",
                        attrs: { tag: "a", to: "/upload-dataset/step1" },
                      },
                      [
                        _c("i", { staticClass: "bx bxs-to-top" }),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "menus.sideMenu.importSection.subTitle.upload"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "side-nav-link-ref",
                        attrs: { tag: "a", to: "/live-import" },
                      },
                      [
                        _c("i", { staticClass: "bx bx-code-alt" }),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "menus.sideMenu.importSection.subTitle.live"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "side-nav-link-ref",
                  attrs: { tag: "a", to: "/datafiles" },
                },
                [
                  _c("i", { staticClass: "bx bxs-data" }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("menus.sideMenu.projectSection.mainTitle")
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              this.loggedInUser.accessLevel !== _vm.accessLevelEnum.EMPLOYEE
                ? _c(
                    "router-link",
                    {
                      staticClass: "side-nav-link-ref",
                      attrs: { tag: "a", to: "/administration" },
                    },
                    [
                      _c("i", { staticClass: "bx bx-cog" }),
                      _c("span", [_vm._v(" Administration ")]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          this.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "side-nav-link-ref",
                      attrs: { tag: "a", to: "/admin-panel" },
                    },
                    [
                      _c("i", { staticClass: "bx bx-ghost" }),
                      _c("span", [_vm._v(" Admin Panel ")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          this.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "side-nav-link-ref",
                      attrs: { tag: "a", to: "/monitoring" },
                    },
                    [
                      _c("i", { staticClass: "bx bx-show" }),
                      _c("span", [_vm._v(" Monitoring ")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          this.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
            ? _c("li", { staticClass: "d-none d-lg-block" }, [
                _vm._m(0),
                _c(
                  "ul",
                  {
                    staticClass: "sub-menu",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "side-nav-link-ref",
                            attrs: { tag: "a", to: "/data-files-stats" },
                          },
                          [
                            _c("i", { staticClass: "bx bx-folder" }),
                            _c("span", [_vm._v(" Data File Stats ")]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "side-nav-link-ref",
                            attrs: { tag: "a", to: "/data-records-stats" },
                          },
                          [
                            _c("i", { staticClass: "bx bx-group" }),
                            _c("span", [_vm._v(" Data Records Stats ")]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "is-parent", attrs: { href: "javascript:void(0);" } },
      [
        _c("i", { staticClass: "bx bx-stats" }),
        _c("span", [_vm._v("Stats")]),
        _c("span", { staticClass: "badge bg-warning float-end ml-2" }, [
          _vm._v("KPI"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }